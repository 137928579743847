// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-biography-tsx": () => import("./../../../src/pages/about/biography.tsx" /* webpackChunkName: "component---src-pages-about-biography-tsx" */),
  "component---src-pages-about-critiques-elda-fezzi-tsx": () => import("./../../../src/pages/about/critiques/elda-fezzi.tsx" /* webpackChunkName: "component---src-pages-about-critiques-elda-fezzi-tsx" */),
  "component---src-pages-about-critiques-luigi-lambertini-tsx": () => import("./../../../src/pages/about/critiques/luigi-lambertini.tsx" /* webpackChunkName: "component---src-pages-about-critiques-luigi-lambertini-tsx" */),
  "component---src-pages-about-critiques-tsx": () => import("./../../../src/pages/about/critiques.tsx" /* webpackChunkName: "component---src-pages-about-critiques-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-works-commissioned-works-tsx": () => import("./../../../src/pages/works/commissioned-works.tsx" /* webpackChunkName: "component---src-pages-works-commissioned-works-tsx" */),
  "component---src-pages-works-drypoints-and-drawings-tsx": () => import("./../../../src/pages/works/drypoints-and-drawings.tsx" /* webpackChunkName: "component---src-pages-works-drypoints-and-drawings-tsx" */),
  "component---src-pages-works-paintings-1960-tsx": () => import("./../../../src/pages/works/paintings-1960.tsx" /* webpackChunkName: "component---src-pages-works-paintings-1960-tsx" */),
  "component---src-pages-works-paintings-1970-tsx": () => import("./../../../src/pages/works/paintings-1970.tsx" /* webpackChunkName: "component---src-pages-works-paintings-1970-tsx" */),
  "component---src-pages-works-paintings-1980-tsx": () => import("./../../../src/pages/works/paintings-1980.tsx" /* webpackChunkName: "component---src-pages-works-paintings-1980-tsx" */),
  "component---src-pages-works-paintings-1990-tsx": () => import("./../../../src/pages/works/paintings-1990.tsx" /* webpackChunkName: "component---src-pages-works-paintings-1990-tsx" */),
  "component---src-pages-works-paintings-2000-tsx": () => import("./../../../src/pages/works/paintings-2000.tsx" /* webpackChunkName: "component---src-pages-works-paintings-2000-tsx" */),
  "component---src-pages-works-posters-tsx": () => import("./../../../src/pages/works/posters.tsx" /* webpackChunkName: "component---src-pages-works-posters-tsx" */),
  "component---src-pages-works-projects-tsx": () => import("./../../../src/pages/works/projects.tsx" /* webpackChunkName: "component---src-pages-works-projects-tsx" */),
  "component---src-pages-works-sculptures-tsx": () => import("./../../../src/pages/works/sculptures.tsx" /* webpackChunkName: "component---src-pages-works-sculptures-tsx" */),
  "component---src-pages-works-sketches-tsx": () => import("./../../../src/pages/works/sketches.tsx" /* webpackChunkName: "component---src-pages-works-sketches-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */)
}

